import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

interface PackoutProps {
  cultureCode: string;
  layout: string;
}

const Packout: React.FC<PackoutProps> = (props) => {
  const history = useHistory();

  React.useEffect(() => {
    if (!window.Packout) {
      return;
    }

    const builder = new window.Packout({
      element: '#packout',
      layout: props.layout,
      culture: props.cultureCode,
    });

    builder.on('route-changed', (url) => history.push(url));
  }, []);

  return <div id="packout" className="packout"></div>;
};

export default Packout;
